import React from "react";
import Section from "./Section";
import { Link } from "./../util/router.js";
import "./Footer.scss";
import "font-awesome/css/font-awesome.min.css";

function Footer(props) {

  return (

    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      className="footer"
    >

      <div className="FooterComponent__container container">
        <div className="columns">
          <div className="column is-5 is-4-widescreen">
            <Link className="brand" to="/">
              <div className="brand-icon">
                <img
                  className="FooterComponent__logo"
                  src={props.logo}
                  alt="Logo"
                />
              </div>
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copyright">{props.copyright}</p>
            )}


            <p className="FooterComponent__copyright">Powered By <b>Miracle Marketing</b></p>

          </div>
          <div className="column is-7 is-6-widescreen is-offset-2-widescreen">
            <div className="columns">
              <div className="column is-4">
                <div className="menu">
                  <p className="menu-label"></p>

                </div>
              </div>
              <div className="column is-4">
                <div className="menu">
                  <p className="menu-label"></p>
              
                </div>
              </div>
              <div className="column is-4">
                <div className="menu">
                  <p className="menu-label">Links</p>
                  <ul className="menu-list">
                   <li>
                      <Link to="/biography">Biography</Link>
                    </li> 
                   <li>
                      <Link to="/filmography">Filmography</Link>
                    </li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Footer;
