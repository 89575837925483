import React from "react";
import HeroSection from "./../components/HeroSection";
import HeroSection3 from "./../components/HeroSection3";
import HeroSection8 from "./../components/HeroSection8";
import StatsSection from "./../components/StatsSection";
import ClientsSection from "./../components/ClientsSection";
import PartnerSection from "./../components/PartnerSection";
import ContentCardsSection from "./../components/ContentCardsSection";
import ProductsRangeSection from "./../components/ProductsRangeSection";
import FeaturesSection from "./../components/FeaturesSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import "font-awesome/css/font-awesome.min.css";
import { useRouter } from "./../util/router.js";
import ImageShadow from 'react-image-shadow';


function IndexPage(props) {
  const router = useRouter();

  return (

    <>
     <HeroSection
        color="dark"
        size="large"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        image=""
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      />

     <HeroSection3
        color="light"
        size="large"
        backgroundImage="/assets/asset1.webp"
        backgroundImageOpacity={1}
        title="Biography"
        image=""
     />

     <HeroSection8
        color="light"
        size="large"
        backgroundImage="/assets/asset2.webp"
        backgroundImageOpacity={1}
        title=""
        image=""
     />

     <ProductsRangeSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
      />



     <NewsletterSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Stay in the know"
        subtitle="Do you like email? Subscribe to our weekly newsletter filled with latest news/updates about Arradhya Maan"
        buttonText="Subscribe"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed 👍"
        image="/assets/fan_mail.webp"
        imageSuccess="/assets/fan_mail_done.webp"
      />

    </>
  );
}

export default IndexPage;