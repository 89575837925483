import React from "react";
import FaqSection from "./../components/FaqSection";

function FaqPage(props) {
  return (
    <FaqSection
      color="light"
      size="large"
      backgroundImage=""
      backgroundImageOpacity={1}
      title="Frequently Asked Questions"
      subtitle=""
    />
  );
}

export default FaqPage;
