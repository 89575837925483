import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection5 from "./../components/HeroSection5";
import "font-awesome/css/font-awesome.min.css"; 
import Certifications from "./../components/Certifications";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import FeaturesSection2 from "./../components/FeaturesSection2";
import FeaturesSection3 from "./../components/FeaturesSection3";
import HeroSectionUC from "./../components/HeroSectionUC";
import HeroSectionKH from "./../components/HeroSectionKH";

function AboutPage() {
  return (
    <>

    <HeroSection5
        color="light"
        size="large"
        backgroundImage="/assets/filmography.webp"
        backgroundImageOpacity={1}
        subtitle=""
        image=""
    />
     <HeroSectionUC
        color="light"
        size="large"
        backgroundImage="/assets/UC-film.webp"
        backgroundImageOpacity={1}
        title=""
        image=""
     />

     <HeroSectionKH
        color="light"
        size="large"
        backgroundImage="/assets/KH-film.webp"
        backgroundImageOpacity={1}
        title=""
        image=""
     />

<br></br></>
  );
}

export default AboutPage;