import React from "react";
import ContactSection2 from "./../components/ContactSection2";
import HeroSection2 from "./../components/HeroSection2";

function ContactPage() {

  return (
    <>
    <HeroSection2
  color="dark"
  size="large"
  title="Contact"
  backgroundImage="/assets/contact.webp"
  backgroundImageOpacity={1}
/>  

    <ContactSection2
      color="white"
      size="small"
      backgroundImage=""
      backgroundImageOpacity={1}
      title=""
      subtitle="Get in touch for Event updates, Press releases and Exclusive Scoop :)"
      buttonText="Send"
      showNameField={true}
      embedSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.12455909285!2d75.85614771500889!3d25.165271683910937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f85e15da90f65%3A0xf05eede06f060d5e!2sG1%20Care%20Pharma!5e0!3m2!1sen!2sin!4v1607941305093!5m2!1sen!2sin"
    />
    </>
  );
}

export default ContactPage;
