import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";
import ImageShadow from 'react-image-shadow';

function HeroSection(props) {
  return (

    <Section  style={{  top: "-10px" }}
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >

    <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-6-desktop has-text-centered-touch">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
    />

          </div>
          <div className="column is-1" />
          <div className="column">
 <figure class="image is-256x256"><a href="https://www.imdb.com/title/tt11046300/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/ujdachaman.webp" alt="Ujda Chaman (2019)"/></a></figure>
          <br></br><br></br>
Ujda Chaman is a 2019 Indian Hindi-language comedy-drama film directed by Abhishek Pathak and produced by Pathak and Kumar Mangat Pathak, Co–Produced Arradhya Maan under the Panorama Studios banner. Starring Sunny Singh, Maanvi Gagroo, Saurabh Shukla, Karishma Sharma and Aishwarya Sakhuja. It was theatrically released in India on 1 November 2019.

<br></br><br></br>
<div class="tile is-ancestor">
  <div class="tile is-parent">
    <div class="tile">
        <ul>
        <li>
        <b>Directed by</b>
        </li>
        <li>
        <b>Produced by</b>
        <br></br><br></br>
        </li>
        <li>
        <b>Co-Produced by</b>
        </li>
        <li>
        <b>Written by</b>
        </li>
        <li>
        <b>Starring</b>
        <br></br><br></br><br></br><br></br>
        </li>
        <li>
        <b>Distributed by</b>
        <br></br><br></br><br></br><br></br>
        </li>
        <li>
        <b>IMDB Rating</b>
        </li>
        <li>
        <b>OTT Platform</b>
        </li>
        </ul>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile">
        <ul>
        <li>
        Abhishek Pathak
        </li>
        <li>
        Kumar Mangat Pathak, Abhishek Pathak 
        </li>
        <li>
        Arradhya Maan
        </li>
        <li>
        Danish J. Singh
        </li>
        <li>
        Sunny Singh, Maanvi Gagroo, Saurabh Shukla, Karishma Sharma
        </li>
        <li>
        <br></br>
        Panorama Studios, PVR Pictures, Glamour Eyes Films, Anand Pandit Motion Pictures
        </li>
        <li>
        <br></br>
        6.9/10
        </li>
        <li>
         <a href="https://www.primevideo.com/detail/Ujda-Chaman/0SGJL4KMQ6KYIZAML5V4BGNWYJ" target="_blank"><img src="/assets/primevideo.svg" height="200px" width="60px" alt="Ujda Chaman (2019)"/></a>
        </li>
        </ul>
    </div>
  </div>
</div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
