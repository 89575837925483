import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import ContentCardsSection from "./../components/ContentCardsSection";
import { useRouter } from "./../util/router.js";

function OurProductsPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection2
        color="light"
        size="large"
        backgroundImage="assets/our-products.jpg"
        backgroundImageOpacity={1}
      />
<StatsSection
        color="dark"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        items={[
          {
            title: "Distributers",
            stat: "50+",
          },
          {
            title: "Products",
            stat: "200+",
          },
          {
            title: "Customers",
            stat: "800+",
          },
          {
            title: "Upto Savings",
            stat: "90%",
          },
        ]}
      />
      <ContentCardsSection
        color="light"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Featured Content"
        subtitle=""
      />
    </>
  );
}

export default OurProductsPage;
