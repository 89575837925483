import React from "react";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router.js";

function AuthPage(props) {
  const router = useRouter();

  return (
<>
    <div class="container">
    <br></br>
    <div class="tile is-ancestor">
      <div class="tile is-8 is-vertical is-parent">
        <div class="tile is-child box">
        <img src="/assets/order-medicine.svg"/>
        </div>
        {/* <div class="tile is-child box">
          <p class="title">Vision</p>
          <p>To emerge as a Model of India’s Fastest Growing Digitally Hitech Pharma & Hybrid E-Clinic with Multiple Chain Outlet.</p>
        </div> */}
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box">
          <p>
          <AuthSection
      color="white"
      size="small"
      backgroundImage=""
      backgroundImageOpacity={1}
      type={router.query.type}
      providers={["google"]}
      afterAuthPath={router.query.next || "/dashboard"}
    />       
    </p>
        </div>
      </div>
    </div>
    <br></br></div>
    
    </>
  );
}

export default AuthPage;
