import React, { useState } from "react";
import NavbarContainer from "./NavbarContainer";
import { Link, useRouter } from "./../util/router.js";
import SectionButton from "./SectionButton";
import { useAuth } from "./../util/auth.js";

function Navbar(props) {
  const auth = useAuth();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
              <img className="image" src={props.logo} alt="Logo" width="50" height="100" />
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/">
              Home
            </Link>
           
            <Link className="navbar-item" to="/biography">
              Biography
            </Link> 
            <Link className="navbar-item" to="/filmography">
              Filmography
            </Link> 
                {/*<a
                  className="navbar-item"
                  href="https://store.g1carepharma.com/"
                  target="_blank"
                >
                  Our Products
                </a>*/}

            {/* <Link className="navbar-item" to="/pricing">
              Plans
            </Link> */}
            <Link className="navbar-item" to="/updates">
              Updates
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>


                      </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;