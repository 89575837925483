import React from "react";
import "./HeroSection.scss";
import Slider from "react-slick";

function HeroSection()  {
    var settings = {
      dots: true,
      speed: 3000,
      autoplay: true,
      slidesToShow: 1,
      fade: true, 
      slidesToScroll: 1,
      infinite: true
    };

    return (
      <Slider {...settings}>
        <div>
          <h3><img src="assets/main_bg.webp" alt="arradhya maan"/></h3>
        </div>
      </Slider>
    );
  }


export default HeroSection;