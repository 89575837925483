import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";

function FaqSection(props) {
  return (
    <>
    <HeroSection2
    color="light"
    size="large"
    backgroundImage="/assets/g1brandlarge.svg"
    backgroundImageOpacity={1}
  />
  <StatsSection
    color="dark"
    size="small"
    backgroundImage=""
    backgroundImageOpacity={1}
    items={[
      {
        title: "Distributers",
        stat: "50+",
      },
      {
        title: "Products",
        stat: "200+",
      },
      {
        title: "Customers",
        stat: "800+",
      },
      {
        title: "Upto Savings",
        stat: "90%",
      },
    ]}
  />
    <Section
      color="{props.color}"
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <Faq
          items={[
            {
              question: "What are generic medicines?",
              answer:
                "Generic medicines are those drugs which are marketed under a non-proprietary or approved name rather than a proprietary (Patent) or brand name. Generic drugs are equally effective and inexpensive compared to their branded counterparts. E.g.:- Nimugin contain the molecule Nimesulide and market with the brand name of Nimugin while generic version also contain molecule Nimesulide and market with the name of Nimesulide only.",
            },
            {
              question: "Why are generic medicines cheaper?",
              answer:
                "Generic medicine makers don’t face the same costs as manufacturers of brand-name drugs as they often invent the drug, a process that can cost hundreds of millions of dollars. The rationale behind drug patents: They give pharmaceutical companies a period of years when only they can make money on a product in which they have made a large investment. That investment also includes advertising. For a generic manufacturer, no such investment is required — not in development and not in marketing. The drug’s formula is known, the clinical trials are complete; the generic maker’s only requirement is to demonstrate to regulators that its version is as good and effective in humans as the original. That is an enormous economic advantage for these companies, which is why their drugs are much cheaper.",
            },
            {
              question: "Can anyone purchase generic medicines directly from a pharmacy?",
              answer:
                "No, A Doctor’s prescription is required to buy generic medicines, while branded medicines can be bought directly from the counter.",
            },
            {
              question: "Are generic medicines FDA approved?",
              answer:
                "FDA's Office of Generic Drugs reviews the application and make sure the generic medicine with the following standard; • The active ingredient in the Generic drug is the same as that of the brand-name drug/innovator drug • The generic medicine is the same strength • The medicine is the same type of product (such as a tablet or an injectable) • The medicine has the same route of administration (such as oral or topical • It has the same use indications • Generic drug companies must submit evidence that all the ingredients used in their products are acceptable, and FDA must review that evidence • (Except Color, flavouring etc. which does not affect the performance of the medicine) • It lasts for at least the same amount of time • It is manufactured under the same strict standards as the brand-name medicine.",
            },
            {
              question: "What are your product range?",
              answer:
                "We primarily deal in ayurvedic, allopathic, homeopathic, cosmetic, health suppliments items from reputed brands.",
            },
            {
              question: "Whats benefit comsumers get from buying generic medicines?",
              answer:
                "With generic medicines, a patient can save upto 90% on their medicine expenses. As the medicines are available at an affordable price.",
            },
            {
              question: "How can i start my own medical business?",
              answer:
                "Candidate interested must be of legal age 18+, a citizen of India with a minimum investment required from Rs 50000 - Rs 500000. Pharma degree/diploma is not mandatory. To know more details fill up the form in the franchise enquiry page to connect with us.",
            },
            {
              question: "What is the minimum invested required?",
              answer:
                "Candidate can apply for our partnership with a minimum of Rs 50000 to 500000.",
            },
          ]}
        />
      </div>
    </Section>
    </>
  );
}

export default FaqSection;
