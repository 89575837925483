import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
function ContentCardsSection(props) {


  return (
    <Section
    color="white"
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title=""
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />


<div class="tile is-ancestor">
  <div class="tile is-6 is-vertical is-parent">
    <div class="tile is-child   is-white">
      <p class="title">Phone</p>
      <p>
        <ul>
        <li>
        India: +918306877797
        </li>
        </ul>
        </p>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile is-child   is-white">
      <p class="title">Email</p>
      <p>
        <ul>
        <li>
        India: management@arradhyamaan.com
        </li>
        </ul>
        </p>
    </div>
  </div>
</div>

      </div>
    </Section>
  );
}

export default ContentCardsSection;
