import React from "react";
import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import BioPage from "./biography";
import FilmoPage from "./filmography";
import FaqPage from "./faq";
import FranchiseEnquiryPage from "./updates";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import OurProductsPage from "./our-products";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";

function App(props) {
  return (
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="dark"
            spaced={false}
            size="large"
            logo="assets/initials-small.png"
          />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/biography" component={BioPage}/>

            <Route exact path="/filmography" component={FilmoPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route
              exact
              path="/updates"
              component={FranchiseEnquiryPage}
            />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/settings/:section" component={SettingsPage} />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route exact path="/our-products" component={OurProductsPage} />

            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            color="white"
            size="small"
            backgroundImage=""
            backgroundImageOpacity={1}
            copyright="©2020 Arradhya Maan. All Rights Reserved."
            logo="assets/logo.webp"
          />
        </>
      </Router>
    </ProvideAuth>
  );
}

export default App;
