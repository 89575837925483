import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import InstagramEmbed from 'react-instagram-embed';
import ProductsRangeSection from "./../components/ProductsRangeSection";

function FranchiseEnquiryPage() {
  return (
    <>
      <HeroSection2
        color="light"
        size="large"
        title="Updates"
        backgroundImage="/assets/c2.png"
        backgroundImageOpacity={1}
      />

      <div class="container">
      <br></br>

<div class="tile is-ancestor">
  <div class="tile is-6 is-vertical is-parent">
    <div class="tile is-child box notification is-white">
<InstagramEmbed
  url='https://www.instagram.com/p/CJIk03zn7rK/'
  clientAccessToken='384052176218347|a04c88ff4fcc8be4529bac80855bcb16'
  maxWidth={500}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile is-child box notification is-white">
   <InstagramEmbed
  url='https://www.instagram.com/p/CJIqKM2H1u6/?utm_source=ig_web_copy_link'
  clientAccessToken='384052176218347|a04c88ff4fcc8be4529bac80855bcb16'
  maxWidth={500}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
    </div>
  </div>



</div>
<div class="tile is-ancestor">
  <div class="tile is-6 is-vertical is-parent">
    <div class="tile is-child box notification is-white">
<InstagramEmbed
  url='https://www.instagram.com/p/CI8D-9WHwcT/?utm_source=ig_web_copy_link'
  clientAccessToken='384052176218347|a04c88ff4fcc8be4529bac80855bcb16'
  maxWidth={500}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile is-child box notification is-white">
   <InstagramEmbed
  url='https://www.instagram.com/p/CI5KuPXHfyA/?utm_source=ig_web_copy_link'
  clientAccessToken='384052176218347|a04c88ff4fcc8be4529bac80855bcb16'
  maxWidth={500}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
    </div>
  </div>



</div>

<br></br></div>
       <ProductsRangeSection
        color="white"
        title="My Social Media Presence"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
      />
      </>
  );
}

export default FranchiseEnquiryPage;
