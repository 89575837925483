import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection4 from "./../components/HeroSection4";
import "font-awesome/css/font-awesome.min.css"; 
import ProductsRangeSection from "./../components/ProductsRangeSection";

function AboutPage() {
  return (
    <>

     <HeroSection4
        color="light"
        size="large"
        backgroundImage="/assets/biography.webp"
        backgroundImageOpacity={1}
        subtitle=""
        image=""
     />
       <ProductsRangeSection
        color="white"
        title="My Social Media Presence"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
      />

<br></br></>
  );
}

export default AboutPage;