import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import ContentCards from "./ContentCards";

function ContentCardsSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
         <div className="container has-text-centered">
        <ContentCards
          items={[
            {
              image: "",
              title: "",
              subtitle: "Taking a liking for Film & Acting from the age of 12 to climbing the ladder of success at the age of 19, Aaradhya Maheshwari, Better known as Arradhya Maan in an Indian Actor/Producer best known for his work on Bollywood films Ujda Chaman(2019)  and Khuda Haafiz(2020). He was on family tour in London. He started pursuing Acting & film making as a Profession right after his high school. He get a chance to visit to Warner Bros Studio, London at the age of 16. That moment kick his career.Arradhya Maan made his debut as Co-producer with comedy-drama movie 'Ujda Chaman' in 2019. Film get good box office response & audience love. It give Arradhya a new energy for second project. After getting a film experience now Arradhya maan ready for OTT debut with an action thriller Khuda Haffiz (2020).",
            }
          ]}
        />
        </div>
      </div>
    </Section>
  );
}

export default ContentCardsSection;
