import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Clients from "./Clients";
import ImageShadow from 'react-image-shadow';

function ClientsSection(props) {
  return (
    <Section style={{  top: "-10px" }}
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <div class="columns">
          <div class="column">
 <figure class="image is-128x128"><a href="https://www.imdb.com/name/nm11078633/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/imdb-am.svg" height="128px" width="128px" alt="arradhya maan imdb"/></a></figure>

  </div>
  <div class="column">
 <figure class="image is-128x128"><a href="https://www.facebook.com/arradhyamaanofficial" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/facebook-am.svg" height="128px" width="128px" alt="arradhya maan facebook"/></a></figure>

  </div>
  <div class="column">
 <figure class="image is-128x128"><a href="https://twitter.com/ArradhyaMaan" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/twitter-am.svg" height="128px" width="128px" alt="arradhya maan twitter"/></a></figure>

  </div>
    <div class="column">
 <figure class="image is-128x128"><a href="https://www.instagram.com/arradhyamaanofficial/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/instagram-am.svg" height="128px" width="128px" alt="arradhya maan instagram"/></a></figure>

  </div>
    <div class="column">
 <figure class="image is-128x128"><a href="https://www.youtube.com/channel/UC7neuTRZWKFVqjfSnr-BnjA" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/youtube-am.svg" height="128px" width="128px" alt="arradhya maan youtube"/></a></figure>

  </div>
    <div class="column">
 <figure class="image is-128x128"><a href="https://soundcloud.com/arradhyamaan" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/soundcloud-am.svg" height="128px" width="128px" alt="arradhya maan soundcloud"/></a></figure>

  </div>
</div>

        
      </div>
    </Section>
  );
}

export default ClientsSection;
