import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import "./ContactSection2.scss";
import ContentCardsSection from "./../components/ContentContactSection";

function ContactSection2(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <ContentCardsSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Featured Content"
        subtitle=""
      />
        <div className="columns">
          <div className="column">
            <Contact
              parentColor={props.parentColor}
              showNameField={props.showNameField}
              buttonText={props.buttonText}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContactSection2;
