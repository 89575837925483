import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";
import ImageShadow from 'react-image-shadow';

function HeroSection(props) {
  return (
    <Section style={{  top: "-10px" }}
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-4-desktop has-text-centered-touch">


          </div>
          <div className="column is-2"/>

          <div className="column">
            <SectionHeader
              title="Filmography"
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
<div class="columns">
  <div class="column">
 <figure class="image is-256x256"><a href="https://www.imdb.com/title/tt11046300/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/ujdachaman-poster.jpg" alt="Ujda Chaman (2019)"/></a></figure>
                  <br></br>
  </div>
  <div class="column">
 <figure class="image is-256x256"><a href="https://www.imdb.com/title/tt7363104/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/khudahaafiz-poster.jpg" alt="Khuda Haafiz (2020)"/></a></figure>
          <br></br>
  </div>
</div>


          </div>
        </div>
      </div>

    </Section>

  );
}

export default HeroSection;
