import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";
import ImageShadow from 'react-image-shadow';
import ImageDangling from 'react-image-dangling';

function HeroSection(props) {
  return (
    <Section  style={{  top: "-10px" }}
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="columns is-vcentered is-desktop">

          <div className="column is-6-desktop has-text-centered-touch" >
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
             <figure class="image is-256x256"><a href="https://www.imdb.com/title/tt11046300/" target="_blank"><ImageShadow shadowHover shadowRadius={20} src="/assets/khudahaafiz.webp" alt="Ujda Chaman (2019)"/></a></figure>
             <br></br><br></br>
Khuda Haafiz is a 2020 Indian Hindi-language action thriller film written and directed by Faruk Kabir and produced by Kumar Mangat Pathak, Abhishek Pathak, Associate Producer Arradhya Maan under Panorama Studios. It stars Vidyut Jammwal and Shivaleeka Oberoi, with Annu Kapoor, Aahana Kumra and Shiv Panditt in supporting roles. Set during the financial crisis of 2007–2008, the film is inspired by true events and follows Sameer Chaudhary, a young man who races against time to rescue his kidnapped wife Nargis from flesh traders.
             <br></br><br></br>
<div class="tile is-ancestor">
  <div class="tile is-parent">
    <div class="tile">
        <ul>
        <li>
        <b>Directed by</b>
        </li>
        <li>
        <b>Produced by</b>
        <br></br><br></br>
        </li>
        <li>
        <b>Associate Producer</b>
        </li>
        <li>
        <b>Written by</b>
        </li>
        <li>
        <b>Starring</b>
        <br></br><br></br><br></br><br></br>
        </li>
        <li>
        <b>Distributed by</b>
        <br></br><br></br>
        </li>
        <li>
        <b>IMDB Rating</b>
        </li>
        <li>
        <b>OTT Platform</b>
        </li>
        </ul>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile">
        <ul>
        <li>
        Faruk Kabir
        </li>
        <li>
        Kumar Mangat Pathak, Abhishek Pathak 
        </li>
        <li>
        Arradhya Maan
        </li>
        <li>
        Faruk Kabir
        </li>
        <li>
        Vidyut Jammwal, Shivaleeka Oberoi, Annu Kapoor, Shiv Panditt, Aahana Kumra
        </li>
        <li>
        <br></br>
        Panorama Studios, Disney+ Hotstar
        </li>
        <li>
        <br></br>
        7.1/10
        </li>
        <li>
         <a href="https://www.hotstar.com/in/movies/khuda-haafiz/1260036204" target="_blank"><img src="/assets/hotstar.svg" height="250px" width="90px" alt="Khuda Haafiz (2020)"/></a>
        </li>
        </ul>
    </div>
  </div>
</div>
          </div>

          <div className="column is-1"/>

   
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
