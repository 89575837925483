import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <Section  style={{  top: "-10px" }}
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-6-desktop has-text-centered-touch">
            <SectionHeader
              title="Biography"
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
Taking a liking for Film & Acting from the age of 12 to climbing the ladder of success at the age of 20, Aaradhya Maheshwari, Better known as Arradhya Maan in an Indian Actor/Producer best known for his work in Critically Acclaimed Bollywood Hit films Ujda Chaman (2019) and Khuda Haafiz (2020).<br></br><br></br>

Born in the Education Capital of India, Kota (Rajasthan), Arradhya Maan comes from a family of great and renowned Educationationalists. Rights from his youth days creating videos on Youtube and presenting monologues, Film and Acting became his passion.<br></br><br></br>

Arradhya Maan made his debut as Co-Producer with comedy-drama movie Ujda Chaman in 2019. Film focused on a rapidly growing social issue “Pattern baldness” which isn’t discussed actively among our peers. Following the success of Ujda Chaman (2019), Arradhya Maan made his OTT debut with an action thriller Khuda Haafiz (2020) on Disney+ Hotstar with Associate Producer being his Talent.
<br></br><br></br>
Arradhya Maan is now actively working on New projects with Bollywood legends Tanishk Bagchi and more.

          </div>
          <div className="column is-1" />
          <div className="column">

          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
